import React from 'react'

export const PageBanner = ({ children, title }) => (
  <div className="page-banner">
    <div className="image-wrapper">
      {children}
    </div>
    
    <h1>{title}</h1>
  </div>
)
