import React from 'react'

export const PageTextRectangle = ({ className, children, numbering, title, text }) => (
  <div className={`page-text-rectangle${className ? ' ' + className : ''}`}>
    <div className='child-wrapper'>
      {children}
    </div>
    
    {numbering ? <h2 className='numbering'>{numbering}</h2> : null}

    {title ? <h2 className='title'>{title}</h2> : null}
  
    {typeof text === 'string' ? <div className={`text${!title ? ' no-title' : ''}`}>{text}</div> : text.map(paragraph => (
      <div key={paragraph.split()[0]} className='text'>{paragraph}</div>
    ))}
  </div>
)

