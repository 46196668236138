import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';

import image1 from '../assets/images/galerija_1.jpg'
import image2 from '../assets/images/galerija_2.jpg'
import image3 from '../assets/images/galerija_3.jpg'
import image4 from '../assets/images/galerija_4.jpg'

const slideImages = [
  image1,
  image2,
  image3,
  image4,
];

const responsive = {
  // 0: { items: 1 },
  // 568: { items: 2 },
  1024: { items: 1 },
};

// const createItems = (length, [handleClick]) => {
//   let deltaX = 0;
//   let difference = 0;
//   const swipeDelta = 20;
//
//   return Array.from({ length }).map((item, i) => (
//     <div
//       data-value={i + 1}
//       className="item"
//       onMouseDown={(e) => (deltaX = e.pageX)}
//       onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
//       onClick={() => (difference < swipeDelta) && handleClick(i)}
//     >
//       <span className="item-inner" />
//     </div>
//   ));
// };

const createItems = (length, [handleClick]) => {
  let deltaX = 0;
  let difference = 0;
  const swipeDelta = 20;
  
  return Array.from({ length }).map((item, i) => (
    <div
      // key={i}
      data-value={i + 1}
      className="item"
      onMouseDown={(e) => (deltaX = e.pageX)}
      onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
      onClick={() => (difference < swipeDelta) && handleClick(i)}
    >
      {/*<span className='item-inner'>*/}
        <img
          className='page-banner-image item-inner'
          src={slideImages[i]}
          alt='galerija'
          layout='fullWidth'
          // onDragStart={handleDragStart}
        />
      {/*</span>*/}
      {/*<span className="item-inner" />*/}
    </div>
  ));
};

export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [items] = useState(createItems(slideImages.length, [setActiveIndex]));
  
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);
  // console.log({ activeIndex })
  
  return [
    <AliceCarousel
      key='key-1'
      mouseTracking
      disableDotsControls
      disableButtonsControls
      items={items}
      activeIndex={activeIndex}
      responsive={responsive}
      onSlideChanged={syncActiveIndex}
    />,
    <div key='key-2' className="b-refs-buttons">
      <button onClick={slidePrev} disabled={activeIndex === 0}>
        Prev
      </button>
      <button onClick={slideNext} disabled={activeIndex === items.length - 1}>
        Next
      </button>
    </div>
  ];
};

