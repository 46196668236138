import React from 'react'

export const Modal = ({ children, handleToggleModal, isOpen }) => {
  return (
    <div className={`modal${isOpen ? ' is-open' : ''}`}>
      <div className='modal-content-wrapper'>
        {children}
      </div>
      
      <div className='modal-footer'>
        <button className='button' onClick={handleToggleModal}>CLOSE</button>
      </div>
    </div>
  )
}

